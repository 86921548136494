@import "variables";
@import "input";

.modal-code {
  header {
    h2 {
      text-align: center;
      font-size: 2em;
      font-weight: 300;
      line-height: 90%;

      small {
        font-size: 1em;
        line-height: 100%;
      }
    }
  }
}

button.button.backspace-button {
  display: block;
  border: none;
  padding: 0;
  background: transparent;
  .button-content {
    padding: 0;
    background: transparent;
    border: none;
  }
  img {
    width: 3.125em;
    height: auto;
  }
}

.code-input {
  @extend .input-well;
  font-family: Inconsolata, monospace;
  font-weight: bold;
  font-size: 3em;
  color: $brand !important;
  text-align: right;
  width: 15em;
  line-height: 4.0625em;
  margin: 0 auto 1em;
  padding: 0.5em 1em;
  height: 4.0625em;
  border-radius: 0.625em;
}

body.falabella {
  .code-input {
    background: linear-gradient(325deg, #fafafa, #e0e0e0);

    input {
      color: $accent-falabella;
      background: #fafafa;
      background-color: #fafafa;
    }
    color: $brand-falabella !important;
  }
}
