.login {
  padding: 2em;
  .input-container {
    label {
      margin-bottom: 0.5em;
      font-size: 0.875em;
      font-weight: 400;
    }
    margin-bottom: 1em;
  }
}
