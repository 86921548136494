.next-playing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
  > div {
    width: 100%;
  }
  
  p {
    font-size: 0.875em;
    font-weight: 200;
    margin: 0;
    line-height: 105%;
  }

  .empty-msg {
    width: 100%;
    text-align: center;
  }
}
