@import "variables";

.modal-download-app {
  .modal-backdrop {
    background: transparent;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0;

    .modal-dialog {
      color: $dark-blue;
      background-color: white;
      width: calc(100vw - 4rem);
      padding: 1.75rem 2em;
      transform: translateY(-1rem);
      transition: transform 1s cubic-bezier(0.77, -0.34, 0.36, 1.32),
        opacity 0.3s linear;

      h1 {
        margin-top: 0;
        font-weight: 400;
      }

      p {
        margin-bottom: 2em;
      }

      .close-modal-button {
        position: absolute;
        right: 0;
        top: 0;

        .button-content {
          border: none;
        }
      }
    }
  }

  .app-store-btn,
  .cancel-btn {
    margin: 2em auto;
    display: block;
  }
  .close-modal-button {
  }
  .cancel-btn {
    margin-bottom: 0;
  }
  .close-modal-button,
  .cancel-btn {
    background: transparent;
    .button-content {
      border: 1px solid $brand;
      color: $brand;
      background: transparent;
    }
  }

  &.collapsed {
    pointer-events: none;
    .modal-container {
      .modal-dialog {
        opacity: 1;
        transform: translateY(calc(100% - 9.25rem)) scale(0.5);
        pointer-events: all;

        .close-modal-button {
          opacity: 0;
        }
      }
    }

    &.modal-not-loaded {
      .modal-container {
        .modal-dialog {
          opacity: 0;
          transform: translateY(calc(100% - 6rem)) scale(0.5);
        }
      }
    }
  }
}

body.falabella {
  .modal-download-app {
    background: $dark-blue;

    .modal-container {
      .modal-dialog {
        background-color: $dark-blue;
        color: white;
      }
    }
    .close-modal-button,
    .cancel-btn {
      background: transparent;
      .button-content {
        border: 1px solid $brand-falabella;
        color: $brand-falabella;
        background: transparent;
      }
    }
  }
}
