@use "sass:math";

@import "../../../../styles/variables";

.box {
  border: none;
  padding: 0;
  $box-width: 8.75em;
  $box-height: 8.25em;
  width: $box-width;
  height: $box-height;
  margin: 0.5em 0.5em 1.5em;
  cursor: pointer;
  transition: all 0.3s;
  background: transparent;
  font-size: 1em;

  .box-content {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .box-info {
      width: 100%;
      color: white;
      padding: 0 0 0 0.625em;
      text-align: left;

      h3 {
        font-size: 0.825em;
        font-weight: 400;
        margin: 0;
        color: white;
      }

      p {
        font-style: 0.875em;
        font-weight: 300;
        margin: 0;
        color: white;
      }
    }

    .cover-grid {
      border-radius: 0.25em;
      width: $box-height;
      height: $box-height;
      flex-shrink: 0;
      z-index: 0;
      background-color: black;
      color: white;
      transition: opacity 0.3s;
      overflow: hidden;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    > svg,
    > img {
      height: 3.375em;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;

      path,
      rect,
      ellipse {
        fill: white;
        stroke-width: 4px;
        stroke: white;
      }
    }
  }

  &.heading {
    // box-shadow: none;
    width: calc(280px + 1em);
    height: 56px;

    .box-content {
      &:after {
        opacity: 1;
      }
      .cover-grid {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
    .box-info {
      margin-top: 0;
      margin-left: 38px;
    }
    @media screen and (min-width: 500px) {
      width: calc(500px - 3em);
    }
  }

  &.selected,
  &:focus {
    .cover-grid {
      box-shadow: 0 5px 8px rgba($brand, 0.5);
    }
  }

  &.hidden {
    box-shadow: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    transform: scale(0);
    .box-content {
      width: 124px;
      height: 124px;
    }
  }
}

.cover-grid {
  display: flex;
  flex-wrap: wrap;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @for $i from 1 through 10 {
    &.grid-#{$i} {
      > img {
        width: #{math.div(100%, $i)};
        height: #{math.div(100%, $i)};
      }
    }
  }
}

.box-name-wrapper {
  position: relative;
  display: block;
  text-align: center;
  padding-top: 0.25rem;

  span.box-category-tag {
    position: absolute;
    bottom: calc(100% + 4px);
    left: 0;
  }
  img.icon.icon-tempo {
    width: 15px;
    position: absolute;
    bottom: calc(100% + 2px);
    right: 10px;
  }
}
span.box-category-tag {
  font-size: 0.75em;
  font-weight: bold;
  background-color: $brand;
  padding: 1px 3px;
  border-radius: 2px;
  color: $dark-blue;
}

body.falabella {
  .box {
    .box-content {
      .box-info {
        color: white;

        h3 {
          color: white;
        }

        p {
          color: white;
        }
      }

      .cover-grid {
        background-color: black;
        color: white;
      }

      > svg,
      > img {
        path,
        rect,
        ellipse {
          fill: white;
          stroke: white;
        }
      }
    }

    &.selected,
    &:focus {
      .cover-grid {
        box-shadow: 0 5px 8px rgba($brand-falabella, 0.5);
      }
    }
  }
  span.box-category-tag {
    background-color: $brand-falabella;
    color: $dark-blue;
  }
}
