@import "variables";

.code-widget {
  .input-wrapper {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 15.25em;
    height: 4.3125em;
    margin-bottom: 1em;

    .input-well {
      width: 15.25em;
      height: 4.3125em;

      input {
        width: 3.75em;
        height: 1.0125em;
        line-height: 1.0125em;
        text-align: right;
        padding: 0 0.5em;

        .kiosk & {
          background-color: $dark-blue;
        }
      }
    }

    button {
      position: absolute;
      left: calc(100% + 6px);
      top: 0.9375em;

      svg {
        width: 3.125em;

        rect,
        path,
        ellipse {
          fill: $grey;
        }
      }
    }
  }
}

.kiosk .code-widget {
  .input-wrapper {
    .input-well {
      input {
        background-color: $dark-blue;
      }
    }

    button {
      svg {
        rect,
        path,
        ellipse {
          fill: white;
        }
      }
    }
  }

  .digit-pad .digit-row .button.as-button.normal.bordered.digit-button {
    border-color: white;
    &,
    .button-content {
      color: white;
    }
  }
}
