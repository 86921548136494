.modal-credit-card.modal-wrapper {
  .modal-dialog {
    .modal-body {
      text-align: center;
      .input-container {
        text-align: left;
      }
      .add-card-button {
        width: 100%;
      }
    }
  }
}
