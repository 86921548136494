.ad-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  &,
  * {
    pointer-events: none;
  }
  z-index: 1000;

  &.hide {
    opacity: 0;
    .ad {
      &,
      &.ad-full,
      &.ad-bottom {
        pointer-events: none;
      }
    }
  }

  .ad {
    position: absolute;
    transition: all 1s;
    transition-timing-function: ease-in-out;

    &.ad-full {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 1;
      pointer-events: initial;
    }

    &.ad-bottom {
      width: 100vw;
      height: 56.5vw;
      background-position: bottom center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      bottom: 0;
      left: 0;
      opacity: 1;
      pointer-events: initial;
    }

    &.to-right {
      left: 100vw;
      opacity: 0;
      pointer-events: none;
    }

    &.to-left {
      left: -100vw;
      transition: none;
      opacity: 0;
      pointer-events: none;
    }

    &.to-bottom {
      bottom: -50vh;
      opacity: 0;
      pointer-events: none;
    }
  }
}
