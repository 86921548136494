.loader {
  width: 45px;
  height: 45px;
  margin: auto;
  display: block;

  .view-more-button & {
    margin: -15px auto;
  }
}
