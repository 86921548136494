.terms-modal.modal-wrapper .modal-container .modal-dialog {
  .terms-container {
    background-color: white;
    color: black;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    height: 70vh;
    margin-bottom: 1rem;

    text-align: left;

    h1,
    h2,
    h3 {
      text-align: left;
      font-size: 1.4rem;
    }

    p {
      text-align: justify;
    }

    a {
      color: #007bff;
      word-break: break-all;
    }
  }

  button.success {
    margin-left: auto;
    display: block;

    .loader {
      margin: -15px;
    }
  }
}
