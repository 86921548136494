/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 1, 2021 */

@font-face {
  font-family: Gotham;
  src: url("../fonts/gotham-black-webfont.woff2") format("woff2"),
    url("../fonts/gotham-black-webfont.woff") format("woff"),
    url("../fonts/gotham-black-webfont.ttf") format("truetype"),
    url("../fonts/gotham-black-webfont.svg#gothamblack") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gothambold-webfont.woff2") format("woff2"),
    url("../fonts/gothambold-webfont.woff") format("woff"),
    url("../fonts/gothambold-webfont.ttf") format("truetype"),
    url("../fonts/gothambold-webfont.svg#gothambold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gothambolditalic-webfont.woff2") format("woff2"),
    url("../fonts/gothambolditalic-webfont.woff") format("woff"),
    url("../fonts/gothambolditalic-webfont.ttf") format("truetype"),
    url("../fonts/gothambolditalic-webfont.svg#gothambold_italic") format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gothambook-webfont.woff2") format("woff2"),
    url("../fonts/gothambook-webfont.woff") format("woff"),
    url("../fonts/gothambook-webfont.ttf") format("truetype"),
    url("../fonts/gothambook-webfont.svg#gothambook") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gothambookitalic-webfont.woff2") format("woff2"),
    url("../fonts/gothambookitalic-webfont.woff") format("woff"),
    url("../fonts/gothambookitalic-webfont.ttf") format("truetype"),
    url("../fonts/gothambookitalic-webfont.svg#gothambook_italic") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gothamlight-webfont.woff2") format("woff2"),
    url("../fonts/gothamlight-webfont.woff") format("woff"),
    url("../fonts/gothamlight-webfont.ttf") format("truetype"),
    url("../fonts/gothamlight-webfont.svg#gothamlight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gothamlightitalic-webfont.woff2") format("woff2"),
    url("../fonts/gothamlightitalic-webfont.woff") format("woff"),
    url("../fonts/gothamlightitalic-webfont.ttf") format("truetype"),
    url("../fonts/gothamlightitalic-webfont.svg#gothamlight_italic")
      format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gothammedium-webfont.woff2") format("woff2"),
    url("../fonts/gothammedium-webfont.woff") format("woff"),
    url("../fonts/gothammedium-webfont.ttf") format("truetype"),
    url("../fonts/gothammedium-webfont.svg#gothammedium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gotham-thin-webfont.woff2") format("woff2"),
    url("../fonts/gotham-thin-webfont.woff") format("woff"),
    url("../fonts/gotham-thin-webfont.ttf") format("truetype"),
    url("../fonts/gotham-thin-webfont.svg#gothamthin") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gotham-thinitalic-webfont.woff2") format("woff2"),
    url("../fonts/gotham-thinitalic-webfont.woff") format("woff"),
    url("../fonts/gotham-thinitalic-webfont.ttf") format("truetype"),
    url("../fonts/gotham-thinitalic-webfont.svg#gothamthinitalic") format("svg");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/gotham-ultraitalic-webfont.woff2") format("woff2"),
    url("../fonts/gotham-ultraitalic-webfont.woff") format("woff"),
    url("../fonts/gotham-ultraitalic-webfont.ttf") format("truetype"),
    url("../fonts/gotham-ultraitalic-webfont.svg#gothamultraitalic")
      format("svg");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Inconsolata;
  src: url("../fonts/Inconsolata-Regular.woff2") format("woff2"),
    url("../fonts/Inconsolata-Regular.woff") format("woff"),
    url("../fonts/Inconsolata-Regular.ttf") format("truetype"),
    url("../fonts/Inconsolata-Regular.svg#Inconsolata-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Inconsolata;
  src: url("../fonts/Inconsolata-Light.woff2") format("woff2"),
    url("../fonts/Inconsolata-Light.woff") format("woff"),
    url("../fonts/Inconsolata-Light.ttf") format("truetype"),
    url("../fonts/Inconsolata-Light.svg#Inconsolata-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Inconsolata;
  src: url("../fonts/Inconsolata-Bold.woff2") format("woff2"),
    url("../fonts/Inconsolata-Bold.woff") format("woff"),
    url("../fonts/Inconsolata-Bold.ttf") format("truetype"),
    url("../fonts/Inconsolata-Bold.svg#Inconsolata-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
