@import "variables";

.modal-success.modal-wrapper {
  .modal-container {
    padding: 0;
    pointer-events: none;
    .modal-dialog {
      width: 100%;
      max-width: 100%;
      height: 100%;
      margin-top: 0;
      padding-top: 6em;
      border-radius: 0;
      background: radial-gradient($brand, transparent);
      background: radial-gradient(circle farthest-side, $brand, transparent);
      color: white;
      pointer-events: none;

      button {
        pointer-events: all;
      }

      h2 {
        display: block;
        > * {
          display: block;
        }
        text-align: center;
        font-size: 2em;
        font-weight: 400;

        small {
          font-size: 1.25em;
        }
        strong {
          font-size: 5em;
        }

        @media screen and (min-width: 768px) {
          font-size: 5em;
          small {
            font-size: 2em;
          }
          strong {
            font-size: 10em;
          }
        }
      }
    }
  }
}

body.falabella {
  .modal-success.modal-wrapper {
    .modal-container {
      .modal-dialog {
        background: radial-gradient($brand-falabella, transparent);
        background: radial-gradient(
          circle farthest-side,
          $brand-falabella,
          transparent
        );
        color: white;
      }
    }
  }
}
