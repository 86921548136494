@import "../../../../styles/custom-scrollbar";

.boxes {
  width: 100vw;
  max-width: calc(100% + 4em);
  margin-left: -2em;
  margin-right: -2em;
  overflow: visible;
  transition: height 0.3s;
  padding: 0;

  .boxes-container {
    @extend .custom-scrollbar;
    @extend .custom-scrollbar-horizontal;

    height: inherit;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 1em;
  }

  &.playlists:not(.with-boxes) {
    height: 0;
  }

  &.is-header {
    height: 3em;
    padding-bottom: 0;
    .boxes-container {
      padding: 0 1em;
    }
  }

  @media screen and (min-width: 344px) and (max-width: 500px) {
    .boxes-container {
      padding: 1em calc(1em + 50vw - 10.75em);
    }
  }
}
body.falabella {
  .boxes {
    .boxes-container {
      @extend .custom-scrollbar-falabella;
      @extend .custom-scrollbar-horizontal-falabella;
    }
  }
}
