.songs {
  min-height: calc(100vh - 10em);
  position: relative;

  .songs-scroll-trigger {
    position: absolute;
    width: 100%;
    height: 80vh;
    z-index: -100;
    pointer-events: none;
    bottom: 0;
  }
}
