.single-local {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  list-style-type: none;
  margin-bottom: 2em;

  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;

    .local-logo {
      width: 3.75em;
      height: 3.75em;
      border-radius: 1.875em;
      margin-right: 0.625em;
      box-shadow: 0 3px 5px rgb(0, 0, 0, 20%);
    }

    h3 {
      font-weight: 500;
      font-size: 1.125em;
    }
  }
  button {
    margin: 0 auto;
    padding: 2px;
    flex-shrink: 0;
  }
  &:first-child {
    flex-wrap: wrap;
    header {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 0;

      h3 {
        font-size: 1.5em;
      }
    }
    button {
      display: block;
      width: 100%;
    }
  }

  &:not(:first-child) {
    align-items: center;
  }
}
