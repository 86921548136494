@import "../../../../styles/variables";

.sonicbox {
  background-color: transparent;
  color: white;
  border-radius: 2.1em;
  padding: 0 2em 2em;
  box-shadow: 0 0 1.25em $dark-blue;

  header {
    background-image: url(../../../../images/background.gif);
    background-size: cover;
    background-position: center;
    border-radius: 1.9em;
    padding: 2em;
    color: white;
    margin-left: -2em;
    margin-right: -2em;
    width: calc(100% + 4em);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 1.9em;
      background-color: rgba($dark-blue, 0.75);
      z-index: 0;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    button.button.accent {
      width: 100%;
      margin-bottom: 2em;
    }
  }

  h2 {
    text-align: center;
    font-weight: 200;
    max-width: 100%;
  }
}

.search-song-input {
  margin: 2em auto;
  z-index: 1;
  input {
    background-color: $dark-blue;
  }
}

.browse-tools {
  position: fixed;
  top: 4em;
  @media screen and (min-width: 768px) {
    width: calc(100% - 2em);
    max-width: calc(768px - 2rem);
  }
  max-width: 100vw;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s;

  background-color: rgba($blue, 0.85);
  z-index: 200;
  margin-left: -2em;
  margin-right: -2em;
  padding: 0 2em 0.5em;

  &.sticked {
    height: auto;
    opacity: 1;
    overflow: auto;
  }

  .boxes-container {
    max-width: 100%;
    overflow: auto;
    padding: 0 2em;
    flex-wrap: nowrap;
  }

  .boxes {
    &:not(.is-header) {
      .box:not(.heading) {
        width: 2.5em;
        height: 2.5em;
        .box-content {
          .cover-grid {
            width: 2.5em;
            height: 2.5em;
          }
          .box-info {
            height: 0;
            width: 2.5em;
            overflow: hidden;
          }
        }
      }
    }

    &.is-header {
      height: 3.125em;
      .boxes-container {
        padding: 0 1em;
        overflow: hidden;
      }
    }

    .box.heading {
      height: 3.125em;
      margin: 0 0.5em;
    }
  }

  .search-song-input {
    margin: 0;
  }

  &.sticked {
  }
}

button.button.scroll-back-button {
  position: fixed;
  bottom: 0;
  margin: 1em;
  z-index: 100;
  transform: translateY(4.5em);
  transition: transform 0.5s;

  &.show {
    transform: translateY(0);
  }

  .button-content {
    padding: 0;
    img {
      width: 3em;
      height: 3em;
      transform: rotate(90deg);
    }
  }
}

body.falabella {
  .sonicbox {
    background-color: transparent;
    color: white;
    box-shadow: 0 0 1.25em $background-dark-falabella;

    header {
      background-image: url(../../../../images/background-falabella.gif);
    }
  }
  .search-song-input {
    input {
      background-color: $background-falabella;
    }
  }
  .browse-tools {
    background-color: rgba($background-falabella, 0.85);
  }
  button.button.scroll-back-button {
    .button-content {
      img {
        filter: hue-rotate(270deg);
      }
    }
  }
}
