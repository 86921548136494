@import "../../../../styles/variables";

.song {
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 1.75em;
  min-height: 3.5em;
  margin-top: 1em;
  margin-bottom: 1em;

  .song-main-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px;
    width: 100%;
  }

  .song-results-list & {
    border-color: $grey;

    &.voted {
      border-color: $brand;
    }
  }

  .next-playing & {
    border-color: rgba(255, 255, 255, 0.5);
    margin: 0.5em auto;

    &:first-child {
      border-color: white;
      border-width: 2px;
    }
  }

  img.cover {
    width: 3em;
    height: 3em;
    border-radius: 1.5em;
    object-fit: cover;
  }

  .song-info {
    margin-left: 0.875em;
    margin-top: auto;
    margin-bottom: auto;
  }
  .songname {
    font-size: 1.125em;
    margin-bottom: 2px;
    font-weight: 500;
  }
  .artist {
    font-size: 0.875em;
    font-weight: 300;
  }

  .button.heart {
    margin: auto 0 auto auto;
    padding: 0.5em;

    svg,
    .loader {
      width: 1.75em;
      height: 1.75em;
    }

    + .vote {
      margin-left: 0;
    }
  }

  .vote {
    margin: auto 0 auto auto;
    font-size: 1.25em;
    font-weight: 600;
    display: block;
    white-space: nowrap;

    .button-content {
      padding: 0;
      height: 2.75em;
      display: flex;
      align-items: center;
    }
    img {
      width: 2.75em;
    }
    small {
      + span {
        margin-left: 8px;
      }
    }
    span {
      display: inline-block;
      height: 2.75em;
      line-height: 2.75em;
      font-size: 1.25em;
      text-align: right;
      margin-right: 0.75em;
      color: $brand;
      font-weight: 600;
    }
  }

  .voter-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .voter {
      width: 1.625em;
      height: 1.625em;
      background-size: cover;
      background-position: center;
      border-radius: 1.3125em;
      background-color: black;
      overflow: hidden;
      margin-left: -10px;
      font-weight: bold;
      font-size: 0.75em;
      text-align: center;
      line-height: 1.5em;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
    }
  }
}

body.falabella {
  .song {
    border: 1px solid rgba(255, 255, 255, 0.15);

    .song-results-list & {
      border-color: $grey;

      &.voted {
        border-color: $brand-falabella;
      }
    }

    .next-playing & {
      border-color: rgba(255, 255, 255, 0.5);

      &:first-child {
        border-color: white;
      }
    }

    .vote {
      span {
        color: $brand-falabella;
      }

      img {
        filter: hue-rotate(270deg);
      }
    }

    .voter-container {
      .voter {
        background-color: black;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
