.tasks-container {
  text-align: center;
  padding: 0 2rem;

  p {
    line-height: 150%;
    font-size: 1.25rem;
    opacity: 0.9;
  }
}

@import "task-spritz-rock-music";
