@import "variables";

.modal-buy.modal-wrapper {
  .modal-container {
    .modal-dialog {
      text-align: center;
      .points-input-widget {
        input {
          color: $brand;
        }
      }
    }
  }

  .credits-message {
    color: $accent;
    text-align: center;
    font-size: 1.25em;
    font-weight: 300;
    line-height: 1.5em;

    strong {
      font-weight: 500;
    }
  }

  h2 {
    text-align: center;
    font-size: 2.5em;
    font-weight: 300;
    span,
    small {
      display: block;
    }
    small {
      font-size: 1.5em;
    }
  }

  .steps-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 768px) {
      min-height: 300px;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    .button.bordered {
      flex-grow: 1;
      flex-shrink: 1;
      width:  6.875em;
    }
    .button.success {
      flex-grow: 1;
      margin-bottom: 1em;
    }
    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: center;
      .button.bordered {
        flex-grow: 0;
        margin-right: 1.25em;
      }
      .button.success {
        margin-bottom: 0;
      }
    }
  }
}

.checkout-summary {
  margin-bottom: 2em;

  h3 {
    font-size: 1em;
    font-weight: 300;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  tr {
    font-size: 1.5em;
    font-weight: 300;
    strong {
      font-weight: 500;
    }
    td {
      padding: 0.625em 0;
    }
  }
  .total-row {
    td {
      border-top: 1px solid $light-grey;
    }
  }
  .number {
    font-weight: 400;
    font-family: Inconsolata, monospace;
    text-align: right;
  }
}

.payments-container {
  .button {
    width: 100%;
  }
}

body.falabella {
  .modal-buy.modal-wrapper {
    .modal-container {
      .modal-dialog {
        .points-input-widget {
          input {
            color: $brand-falabella;
          }
        }
      }
    }

    .credits-message {
      color: $accent-falabella;
    }
  }

  .checkout-summary {
    .total-row {
      td {
        border-top: 1px solid $light-grey;
      }
    }
  }
}
