@use "sass:color";
@import "variables";

@mixin buttonColors($textColor, $colorA, $colorB, $colorC: null) {
  @if ($colorC == null) {
    $colorC: $colorB;
    $colorB: color.mix($colorA, $colorB, 0.5);
  }
  background: linear-gradient(335deg, $colorA, $colorB, $colorC);
  .button-content {
    color: $textColor;
    border-color: rgba($colorB, 0.5);
    background: radial-gradient(
      $colorA 0%,
      $colorB 28%,
      $colorB 52%,
      $colorC 100%
    );
    background-position: -100% -100%;
    background-size: 200% 200%;
    .icon {
      path,
      rect,
      ellipse {
        fill: $textColor;
      }
    }
  }
}

button.button {
  padding: 3px;
  font-size: 0.85em;
  text-transform: uppercase;
  border-radius: 6.25em;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;

  position: relative;
  box-sizing: border-box;

  -webkit-appearance: none;
  -webkit-border-radius: none;

  // background: linear-gradient(175deg, rgb(250, 250, 250), rgb(211, 211, 211));

  .button-content {
    padding: 1em 2em;
    border-radius: 3em;
    @media screen and (min-width: 992px) {
      border-radius: 6.25em;
    }
    // background: rgb(250, 250, 250);
    // border: 1px solid color.mix(rgb(250, 250, 250), rgb(211, 211, 211), 0.5);
  }

  .icon {
    height: 1.25em;
    width: auto;
    position: absolute;
    left: 1em;
    top: 50%;
    transform: translate(0, -50%);
  }

  @include buttonColors(
    $grey,
    rgb(230, 230, 230),
    rgb(240, 240, 240),
    rgb(250, 250, 250)
  );

  &:hover:not(:disabled) {
    transform: scale(1.025);
  }

  &:disabled {
    filter: saturate(0);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.big {
    padding: 0.3125em;
    font-size: 1.5em;
    font-weight: 400;
    width: 100%;

    .icon {
      height: 1.5625em;
      left: 1.5em;
    }

    .button-content {
      padding: 0.25em 1em;
      border-radius: 1.5em;
    }
  }

  &.small {
    padding: 0.125em;
    font-size: 0.875em;
  }

  &.bordered {
    border: 1px solid;
    background: transparent;
    text-transform: none;
    color: $grey;

    .button-content {
      color: $grey;
      border: none;
      background: transparent;
    }
  }

  &.brand,
  &.success {
    @include buttonColors(white, $brand, #00b7ab, #018f85);
    .button-content {
      background-position: -100vw -100vw;
      background-size: 200vw 200vw;
      @media screen and (min-width: 500px) {
        background-position: -500px;
        background-size: 200% 500px;
      }
    }
    &.bordered {
      border: 1px solid $brand;
      background: transparent;
      color: $brand;
      .button-content {
        color: $brand;
        background: transparent;
        border: none;
        .icon {
          path,
          rect,
          ellipse {
            fill: $brand;
          }
        }
      }
    }
  }

  &.blue {
    @include buttonColors(white, $dark-blue, $blue, #003850);
    .button-content {
      background-position: -100vw -100vw;
      background-size: 200vw 200vw;
      @media screen and (min-width: 500px) {
        background-position: -500px;
        background-size: 200% 500px;
      }
    }
    &.bordered {
      border: 1px solid $blue;
      background: transparent;
      color: $blue;
      .button-content {
        background: transparent;
        border: none;
        color: $blue;
        .icon {
          path,
          rect,
          ellipse {
            fill: $blue;
          }
        }
      }
    }
  }

  &.accent {
    @include buttonColors(white, #ff6893, $accent, #ff6893);
    .button-content {
      background-position: -100vw -100vw;
      background-size: 200vw 200vw;
      @media screen and (min-width: 500px) {
        background-position: -500px;
        background-size: 200% 500px;
      }
    }
    &.bordered {
      border: 1px solid $accent;
      background: transparent;
      color: $accent;
      .button-content {
        background: transparent;
        border: none;
        color: $accent;
        .icon {
          path,
          rect,
          ellipse {
            fill: $accent;
          }
        }
      }
    }
  }

  &.facebook {
    @include buttonColors(white, #4f98f5, $facebook-blue, #4f98f5);
  }

  &.google {
    @include buttonColors($grey, white, #f2f2f2, #b3b3b3);
    .button-content {
      background-position: -100vw -100vw;
      background-size: 200vw 200vw;
      @media screen and (min-width: 500px) {
        background-position: -500px;
        background-size: 200% 500px;
      }
    }
  }

  &.as-transparent {
    background: transparent;
    &.brand {
      color: $brand;
    }
    &.accent {
      color: $accent;
    }
  }

  &.as-link,
  &.as-text {
    display: inline;
    background: transparent;
    padding: 0;
    border: none;
    color: $accent;
    transition: color 0.3s;
    .icon {
      path,
      rect,
      ellipse {
        fill: $accent;
        transition: fill 0.3s;
      }
    }
    font-weight: normal;
    text-transform: none;
    border-radius: 0;

    &.brand {
      color: $brand;
    }
    &.accent {
      color: $accent;
    }
    &::before {
      content: none;
      display: none;
    }

    &:hover:not(:disabled) {
      transform: none;
      text-decoration: underline;
    }
  }
}

.facebook-error-msg,
.google-error-msg {
  color: $accent;
  text-align: center;
  margin: 0 auto;
  padding: 0.625em;
  text-transform: none;
}

body.falabella {
  button.button {
    @include buttonColors(
      $grey,
      rgb(230, 230, 230),
      rgb(240, 240, 240),
      rgb(250, 250, 250)
    );

    &.brand,
    &.success {
      @include buttonColors(white, $brand-falabella, #8bad03, #617903);

      &.bordered {
        border: 1px solid $brand-falabella;
        background: transparent;
        color: $brand-falabella;
        .button-content {
          color: $brand-falabella;
          background: transparent;
          .icon {
            path,
            rect,
            ellipse {
              fill: $brand-falabella;
            }
          }
        }
      }
    }

    &.blue {
      @include buttonColors(
        white,
        $background-dark-falabella,
        $background-falabella,
        $brand-falabella
      );
      &.bordered {
        border: 1px solid $blue;
        background: transparent;
        color: $blue;
        .button-content {
          background: transparent;
          color: $blue;
          .icon {
            path,
            rect,
            ellipse {
              fill: $blue;
            }
          }
        }
      }
    }

    &.accent {
      @include buttonColors($blue, #d6f84e, $accent-falabella, #a5c523);

      &.bordered {
        border: 1px solid $accent-falabella;
        color: $accent-falabella;
        .button-content {
          background: transparent;
          color: $accent-falabella;
          .icon {
            path,
            rect,
            ellipse {
              fill: $accent-falabella;
            }
          }
        }
      }
    }

    &.facebook {
      @include buttonColors(white, #4f98f5, $facebook-blue, #4f98f5);
    }

    &.google {
      @include buttonColors($grey, white, #f2f2f2, #b3b3b3);
    }

    &.as-link,
    &.as-text {
      background: transparent;
      color: $accent-falabella;
      .icon {
        path,
        rect,
        ellipse {
          fill: $accent-falabella;
        }
      }

      &.brand {
        color: $brand-falabella;
      }
    }
  }

  .facebook-error-msg,
  .google-error-msg {
    color: $accent-falabella;
  }
}
