$brand: #00ffee;
$accent: #f5376d;
$red: #e72222;
$lightest-grey: #f2f2f2;
$lighter-grey: #cccccc;
$light-grey: #b3b3b3;
$grey: #808080;
$blue: #002d40;
$dark-blue: #002536;

$facebook-blue: #1778f2;

$brand-falabella: #aad500;
$accent-falabella: #c9f31f;
$background-falabella: #2c3b21;
$background-dark-falabella: #202e17;
