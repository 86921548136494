@import "../../../../styles/variables";
@import "../../../../styles/page.scss";

.account-navbar {
  @extend .container;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  margin: 1em -1em 2em;
  z-index: 100;
  border-radius: 1.25em;
  overflow: hidden;

  width: 100%;
  max-width: 100%;
  @media screen and (min-width: 768px) {
    width: calc(100% - 2em);
    max-width: calc(100% - 2em);
  }

  a {
    display: block;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0.75em;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.875em;
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.2),
      transparent,
      rgba(255, 255, 255, 0.08)
    );
    background-color: $dark-blue;
    transition: color 0.3s, background-color 0.3s;

    &.active,
    &:hover,
    &:focus {
      color: $blue;
      background-color: $brand;
    }
  }
}

body.falabella {
  .account-navbar {
    a {
      color: white;
      background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.2),
        transparent,
        rgba(255, 255, 255, 0.08)
      );
      background-color: $dark-blue;

      &.active,
      &:hover,
      &:focus {
        color: $blue;
        background-color: $brand-falabella;
      }
    }
  }
}
