.index-page {
  min-height: calc(100vh - 184px);
  text-align: center;
  padding: 2em;

  button {
    display: block;
    width: 100%;
    margin: 0.625em auto;
  }
}
