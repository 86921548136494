@import "variables";

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $brand;
    outline: none;
    border-radius: 0.25em;
  }
  &.custom-scrollbar-horizontal::-webkit-scrollbar {
    width: auto;
    height: 0.5em;
  }
  &.custom-scrollbar-horizontal::-webkit-scrollbar-track {
    height: 0.5em;
  }
  &.custom-scrollbar-horizontal::-webkit-scrollbar-thumb {
    height: 0.5em;
  }
  &.custom-scrollbar-horizontal {
  }

  &.on-hover-scrollbar {
    &::-webkit-scrollbar-thumb {
      transition: background-color 0.5s linear;
      background-color: $dark-blue;
    }
    &:hover,
    &:active,
    &:focus {
      &::-webkit-scrollbar-thumb {
        background-color: $brand;
      }
    }
  }
}

.custom-scrollbar-falabella {
  @extend .custom-scrollbar;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $brand-falabella;
  }
  &.custom-scrollbar-horizontal-falabella::-webkit-scrollbar {
    width: auto;
    height: 0.5em;
  }
  &.custom-scrollbar-horizontal-falabella::-webkit-scrollbar-track {
    height: 0.5em;
  }
  &.custom-scrollbar-horizontal-falabella::-webkit-scrollbar-thumb {
    height: 0.5em;
  }
  &.custom-scrollbar-horizontal-falabella {
  }

  &.on-hover-scrollbar {
    &::-webkit-scrollbar-thumb {
      background-color: $dark-blue;
    }
    &:hover,
    &:active,
    &:focus {
      &::-webkit-scrollbar-thumb {
        background-color: $brand-falabella;
      }
    }
  }
}

body.falabella {
  @extend .custom-scrollbar-falabella;
}
