@import "../../../../styles/variables";

.now-playing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  .button.heart {
    font-size: 1.25em;
  }

  p {
    font-size: 0.875em;
    font-weight: 200;
    margin: 0;
    line-height: 120%;
  }
  h4 {
    font-size: 1.5em;
    font-weight: 400;
    line-height: 120%;
    margin: 0;

    + p {
      font-size: 1.125em;
      margin-bottom: 2em;
    }
  }

  .now-playing-cover-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  img.song-cover {
    width: 12.5em;
    height: 12.5em;
    flex-shrink: 0;
    border-radius: 6.25em;
    object-fit: cover;
    overflow: hidden;
    background-color: black;
    border: 6px solid white;
    margin-bottom: 2em;
    margin-right: 4.25em;

    .kiosk & {
      margin-right: 0;
    }
  }
}
