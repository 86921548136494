@import "variables";
@import "input";

.points-input-widget {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;

  input {
    font-weight: 600;
    font-size: 4em;
  }

  .input-well input {
    border-radius: 0.275em;
    @media screen and (min-width: 992px) {
      border-radius: 0.25em;
    }
  }

  button.button {
    margin: auto;
    font-size: 0.4em;
    @media screen and (min-width: 992px) {
      font-size: 0.85em;
    }

    .button-content {
      font-size: 4em;
      line-height: 2.5em;
      width: 2.5em;
      height: 2.5em;
      border-radius: 1.25em;
      color: $lighter-grey;
      padding: 0;
      flex-shrink: 0;
    }

    &.btn-plus {
      margin-left: 1em;
    }

    &.btn-minus {
      margin-right: 1em;
      .button-content {
        line-height: 2.5em;
      }
    }
  }
}
