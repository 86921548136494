@import "../../../../styles/variables";

.task-questiongroup-title {
  color: $brand;
  font-size: 1.125rem;
}

.task-questiongroup {
  width: 100%;
  > table {
    width: 100%;
    tr {
      > td {
        border-bottom: 1px dashed rgba($brand, 0.1);
      }
      &:first-child > td {
        border-top: 1px dashed rgba($brand, 0.1);
      }
    }
  }
  .task-questiongroup-question {
    text-align: left;
    padding: 12px;
  }
  .task-questiongroup-radio {
    label {
      display: block;
      padding: 12px;
      cursor: pointer;
      text-transform: uppercase;
      border-radius: 8px;
      min-width: 50px;
    }
    input {
      opacity: 0;
      position: absolute;
      width: 0;
      height: 0;
    }
    &.selected {
      label {
        color: $brand;
        background-color: #0001;
        font-weight: 500;
      }
    }
    &:hover {
      label {
        background-color: rgba($brand, 0.25);
      }
    }
  }
}
