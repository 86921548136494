@import "../../../../styles/variables";

.memory {
  max-width: 100%;
  margin: 0 auto;
}

.memory-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.memory-card-container {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out, opacity 1s;

  .memory-card-front,
  .memory-card-back {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 5px;
  }
  .memory-card-front {
    transform: rotateY(180deg);
  }
  &:not(.ready) {
    transform: rotateY(180deg);
    .memory-card-back {
      opacity: 0;
    }
  }
  &.ready {
    .memory-card-front {
      animation: flipOut 0.5s forwards;
    }
    .memory-card-back {
      animation: flipIn 0.5s forwards;
    }
  }
  &.flipped,
  &.matched {
    transform: rotateY(180deg);
    .memory-card-front {
      animation: flipIn 0.5s forwards;
    }
    .memory-card-back {
      animation: flipOut 0.5s forwards;
    }
  }

  &.matched {
    opacity: 0.1;
    pointer-events: none;
  }
}
.memory-card-front,
.memory-card-back {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-position: center;
}

.memory-solved {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border-radius: 2rem;
  background-color: rgba($blue, 0.85);
  z-index: 99;

  transition: opacity 0.5s, transform 0.5s ease-in-out;

  &.not-visible {
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, -50%) scale(0.25);
  }

  h2 {
    font-size: 2.5rem;
    margin: 0;
  }
  p {
    font-size: 1.25rem;
    margin: 1rem 0 2rem;
  }
  button + button {
    margin-left: 12px;
  }
}

@keyframes flipIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flipOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
