@import "variables";

.input-container {
  position: relative;
  label {
    margin-left: 0.75em;
    margin-bottom: 2px;
    display: block;
    font-size: 0.75em;
    font-weight: 500;
  }
  .clear-input-button {
    position: absolute;
    right: 1px;
    top: 1px;
    font-size: 1.5em;
    width: 37px;
    line-height: 37px;
    color: $red;
  }
}

input {
  color: white;
  background: transparent;
  border: 1px solid white;
  font-size: 1.25em;
  font-weight: 400;
  padding: 0.5em 1em;
  border-radius: 3em;
  display: block;
  width: 100%;
  font-family: inherit;
  outline: none;

  &[type="number"] {
    padding-right: 0;
  }
}

.input-well {
  font-size: 1em;
  padding: 2px;
  background: linear-gradient(325deg, #fafafa, #e0e0e0);
  border-radius: calc(1em + 4px);

  input {
    margin: 0;
    text-align: center;
    color: $accent;
    background: #fafafa;
    background-color: #fafafa;
    border: none;
    -webkit-appearance: none;

    width: 1.25em;
    height: 1.25em;
    line-height: 1.25em;
    border-radius: 0.125em;
    @media screen and (min-width: 992px) {
      width: 2.5em;
      height: 2.5em;
      line-height: 2.5em;
      border-radius: 0.25em;
    }
    padding: 0;
    font-size: 4em;
    font-weight: 500;

    // @for $i from 1 through 5 {
    //   &.digits-#{$i} {
    //     font-size: #{4.5em - $i * 0.8em};
    //   }
    // }
  }
}

.hg-theme-default.simple-keyboard,
.simple-keyboard {
  width: 80vw;
  margin-left: 6vw;
  margin-top: 1.25em;

  .hg-button span {
    color: black;
  }
}
