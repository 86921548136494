.digit-pad {
  margin-bottom: 2em;
  .digit-row {
    display: flex;
    justify-content: center;

    .button.digit-button {
      font-size: 2em;
      margin: 6px;

      .button-content {
        padding: 0;
        width: 3.75em;
        height: 3.75em;
        line-height: 3.75em;
      }
    }
  }
}
