@import "variables";
@import "custom-scrollbar";

.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;
  pointer-events: none;

  &.dark {
    .container {
      border-radius: 1.25em 1.25em 0 0;
      background: linear-gradient(
        0deg,
        rgba($blue, 0) 0%,
        rgba($blue, 0.85) 10%
      );
    }
  }
  &.light {
    background: transparent;

    .container {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.35) 50%,
        rgba(255, 255, 255, 0.5) 100%
      );
    }
  }
}

.navbar {
  margin: 0 auto;

  width: 100%;
  max-width: 100%;
  @media screen and (min-width: 768px) {
    width: calc(100% - 2em);
    max-width: calc(100% - 2em);
  }

  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  color: white;
  transition: color 0.3s;
  pointer-events: none;
  > * {
    pointer-events: all;
  }

  .credits-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin: 0.625em;

    + .credits-container {
      margin-left: 0;
    }

    .credits {
      display: block;
      flex-shrink: 0;
      background-color: $brand;
      color: $dark-blue;
      border-radius: 1.875em;
      border: 1px solid $brand;
      width: 1.75em;
      height: 1.75em;
      line-height: 1.75em;
      text-align: center;
      font-weight: 600;
      letter-spacing: -1px;
      padding-right: 1px;
      font-family: Inconsolata, monospace;
      font-size: 0.875em;
    }
    .label {
      font-size: 0.75em;
      color: $brand;
    }

    &.fcredits-container {
      .credits {
        color: $brand;
        background-color: $dark-blue;
      }
    }
  }
}

.nav-menu-wrapper {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  pointer-events: none;
}

.menu-backdrop {
  background-color: white;
  color: $grey;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 500;

  &.expanded {
    pointer-events: all;
    cursor: pointer;
    opacity: 0.25;
  }
}
.nav-menu-container {
  @extend .custom-scrollbar;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  pointer-events: all;

  background-color: white;
  transition: width 0.5s;
  z-index: 501;

  &,
  a,
  .button.as-link {
    color: $grey;
  }
  overflow: hidden;

  > header,
  > nav {
    width: 80vw;
    display: flex;
    flex-direction: column;
    padding: 2em;

    @media screen and (min-width: 1000px) {
      width: 800px;
    }

    a,
    .button.as-link,
    .input-container {
      text-align: left;
      display: block;
      padding: 1em;
      font-size: 1.25em;

      &,
      input {
        color: $grey;
      }
      .points-input-widget {
        font-size: 0.25em;
        margin-left: auto;
        input {
          padding-left: 0.5em;
          min-width: calc(2em + 14px);
        }
      }
      transition: color 0.3s;
      .icon {
        path,
        rect,
        ellipse {
          fill: $grey;
          transition: fill 0.3s;
        }
      }

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      .radio-image {
        width: 2.5em;
        height: 2.5em;
        margin-right: 0.625em;
        border-radius: 3px;
        border: 2px solid $grey;
        transition: border-color 0.3s;
      }

      .icon {
        left: 0;
        top: 0;
        transform: none;
        position: relative;
        width: 2.5em;
        height: 2.5em;
        margin-right: 0.625em;
      }

      &[aria-current] {
        color: $brand;
        .icon {
          path,
          rect,
          ellipse {
            fill: $brand;
          }
        }
        .radio-image {
          border-color: $brand;
        }
      }
      &:hover:not(:disabled) {
        color: $accent;
        text-decoration: none;
        color: $brand;
        background-color: rgba($brand, 0.1);
        .icon {
          path,
          rect,
          ellipse {
            fill: $brand;
          }
        }
        .radio-image {
          border-color: $brand;
        }
      }
    }

    .reset-zoom-btn {
      font-size: 0.75em;
      margin-left: auto;
    }
  }

  > header {
    padding-bottom: 0;

    .profile-header {
      border-bottom: 1px solid $lighter-grey;
    }
  }

  &.expanded {
    width: 80vw;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: 1000px) {
      width: 800px;
    }
  }

  &.collapsed {
    width: 0;
  }
}

.local-header {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 3em;
    height: 3em;
    display: block;
    object-fit: cover;
    margin: 0 0.625em 0 0;
    border-radius: 0.625em;
  }

  h2 {
    font-size: 1em;
    margin: 0;
  }
}

.toggle-menu-btn {
  border: none;
  background: transparent;
  margin-left: 0;
  margin-right: auto;

  img {
    width: 55px;
    height: 70px;
  }

  svg {
    height: 70px;
    path,
    rect,
    ellipse {
      fill: $brand;
      stroke-width: 4px;
      stroke: $brand;
      transition: fill 0.3s, stroke 0.3s;
    }
  }
}

.dark {
  .navbar {
    color: white;
  }
  .toggle-menu-btn {
    path,
    rect,
    ellipse {
      fill: white;
      stroke-width: 4px;
      stroke: white;
    }
  }
}

.light {
  .navbar {
    color: $blue;
  }
  .toggle-menu-btn {
    path,
    rect,
    ellipse {
      fill: $brand;
      stroke-width: 4px;
      stroke: $brand;
    }
  }
}

body.falabella {
  .navbar-container {
    &.dark {
      .container {
        background: linear-gradient(
          0deg,
          rgba($background-falabella, 0) 0%,
          rgba($background-falabella, 0.85) 10%
        );
      }
    }
    &.light {
      background: transparent;

      .container {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.35) 50%,
          rgba(255, 255, 255, 0.5) 100%
        );
      }
    }
  }

  .navbar {
    .credits-container {
      .credits {
        background-color: $brand-falabella;
        color: $dark-blue;
        border: 1px solid $brand-falabella;
      }
      .label {
        color: $brand-falabella;
      }

      &.fcredits-container {
        .credits {
          color: $brand-falabella;
          background-color: $dark-blue;
        }
      }
    }
  }

  .menu-backdrop {
    background-color: white;
    color: $grey;
  }
  .nav-menu-container {
    @extend .custom-scrollbar-falabella;
    background-color: white;

    &,
    a,
    .button.as-link,
    input,
    label {
      color: $grey;
    }

    > header,
    > nav {
      a,
      .button.as-link {
        color: $grey;
        .icon {
          path,
          rect,
          ellipse {
            fill: $grey;
          }
        }

        .radio-image {
          border: 2px solid $grey;
        }

        &[aria-current] {
          color: $brand-falabella;
          .icon {
            path,
            rect,
            ellipse {
              fill: $brand-falabella;
            }
          }
          .radio-image {
            border-color: $brand-falabella;
          }
        }
        &:hover:not(:disabled) {
          color: $accent-falabella;
          color: $brand-falabella;
          background-color: rgba($brand-falabella, 0.1);
          .icon {
            path,
            rect,
            ellipse {
              fill: $brand-falabella;
            }
          }
          .radio-image {
            border-color: $brand-falabella;
          }
        }
      }
    }

    > header {
      .profile-header {
        border-bottom: 1px solid $lighter-grey;
      }
    }
  }

  .toggle-menu-btn {
    svg {
      path,
      rect,
      ellipse {
        fill: $brand-falabella;
        stroke: $brand-falabella;
      }
    }
  }

  .dark {
    .navbar {
      color: white;
    }
    .toggle-menu-btn {
      path,
      rect,
      ellipse {
        fill: white;
        stroke: white;
      }
    }
  }

  .light {
    .navbar {
      color: $blue;
    }
    .toggle-menu-btn {
      path,
      rect,
      ellipse {
        fill: $brand-falabella;
        stroke: $brand-falabella;
      }
    }
  }
}
