.display {
  max-height: calc(100vh - 186px);
  &.container,
  > .container {
    width: 100%;
  }

  .navbar {
    max-width: 100%;
  }

  .now-playing {
    img.song-cover {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .next-playing .empty-msg {
    font-size: 2em;
  }

  .now-playing {
    text-align: left;

    > p {
      font-size: 2em;
      text-align: left;
    }

    .song {
      width: 100%;
      border-width: 2px;
    }
  }

  .next-playing {
    > p {
      margin: 3em 0;
    }
  }

  .song {
    border-radius: 2.5em;
    img.cover {
      width: 4em;
      height: 4em;
      border-radius: #{4em * 0.5};
    }

    .songname {
      font-size: 2em;
    }
    .artist {
      font-size: 1.5em;
    }

    .voter-container .voter {
      width: 2.25em;
      height: 2.25em;
      border-radius: #{2.25em * 0.5};
    }
  }
}

.get-app-qr {
  margin: 2em auto;
  width: 23.75em;
  height: 23.75em;
  display: block;
}

.app-store-btns {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.sonicbox-display {
  display: flex;
  padding: 5em;

  .col-50 {
    flex-basis: 50%;
  }
}
