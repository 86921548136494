@import "variables";

.button.heart {
  background: transparent;
  padding: 1em;
  line-height: 1.875em;
  display: flex;
  background: transparent;

  .loader,
  svg {
    width: 1.875em;
    height: 1.875em;
    rect,
    ellipse,
    path {
      fill: $brand;
    }
  }

  &:hover {
    svg {
      rect,
      ellipse,
      path {
        fill: $accent;
      }
    }
  }
}

body.falabella {
  .button.heart {
    background: transparent;
    background: transparent;

    .loader,
    svg {
      rect,
      ellipse,
      path {
        fill: $brand-falabella;
      }
    }

    &:hover {
      svg {
        rect,
        ellipse,
        path {
          fill: $accent-falabella;
        }
      }
    }
  }
}
