@import "variables";

.credit-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1.5em;
  background-color: white;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  border-radius: 2em;
  margin-bottom: 1em;

  &.selected {
    background-color: $brand;
    color: white;
  }

  .button-delete {
    background: transparent;
    padding: 0;
    width: 32px;
    height: 32px;

    .button-content {
      background: transparent;
      padding: 8px;
    }
  }
}

body.falabella {
  .credit-card {
    background-color: white;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);

    &.selected {
      background-color: $brand-falabella;
      color: white;
    }

    .button-delete {
      background: transparent;

      .button-content {
        background: transparent;
      }
    }
  }
}
