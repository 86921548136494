@import "../../../../styles/variables";

.task-form {
  width: 640px;
  max-width: calc(100% - 4rem);
  margin: 0 auto;
}

.task-form-label {
  display: block;
  text-align: left;
  margin: 1rem 1rem 0.5rem;
}

.task-form-input-container {
  margin-bottom: 2rem;
}

.task-form-input {
  box-shadow: 0 0 11px rgba($blue, 0.2) inset;
  border: none;
  border-radius: 0.5rem;
  background: white;
  color: black;
  font-size: 1.25rem;
}

.task-form-radio {
}

.task-form-radiogroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.task-form-radio-label {
  display: flex;
  align-items: center;
  padding: 10px;

  input,
  span {
    margin-top: 0;
    cursor: pointer;
    transition: background-color 0.5s, border-color 0.5s, color 0.5s;
  }

  input {
    flex-shrink: 0;
    flex-grow: 0;
  }

  &:focus,
  &:hover {
    color: $brand;
    input {
      border-color: $brand;
    }
  }
}

.task-form-radio-input {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  border: none;
  appearance: none;
  background-color: white;
  border: 2px solid;
  display: block;
  padding: 0;

  &:checked {
    border-color: $brand;
    background-color: $blue;

    + span {
      color: $brand;
    }
  }
}

.task-form-radio-text {
}

.task-form-textarea {
  @extend .task-form-input;
  padding: 0.75rem 1rem;
  width: 100%;
  height: 120px;
}

.task-form-error {
  color: $red;
  text-align: left;
  margin: 0.25rem 1rem;
  font-weight: 500;
}

.task-form-face {
  display: block;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
}
