@import "page";
@import "custom-scrollbar";

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 300;
  margin: 0;
  padding: 0;
  overflow: hidden;

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($dark-blue, 0.75);
    cursor: pointer;
  }
  .modal-container {
    @extend .custom-scrollbar;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: none;
    padding: 1em;
    margin: 0;
    // background: linear-gradient(45deg, red 0%, lime 50%, yellow 100%);

    .modal-dialog {
      @extend .container;
      background-color: white;
      color: $grey;
      padding: 2em;
      position: relative;
      z-index: 1;
      pointer-events: all;
      border-radius: 1.25em;

      @media screen and (min-width: 768px) {
        margin-top: 3em;
        margin-bottom: 3em;
        max-width: calc(768px - 2em);
      }

      input {
        color: $grey;
        border-color: $grey;
      }

      .modal-header {
        display: flex;
      }
      .close-modal-button {
        border: none;
        font-size: 2.125em;
        text-align: center;
        margin-left: auto;
        .button-content {
          padding: 0 10px;
          border-radius: 1.25em;
          line-height: 1.25em;
          height: 1.25em;
          width: 1.25em;
        }
      }

      h1 {
        text-align: center;
      }
    }
  }

  &.collapsed {
    .modal-container {
      overflow: hidden;
    }
  }
}

body.falabella {
  .modal-wrapper {
    .modal-backdrop {
      background-color: rgba(0, 54, 3, 0.75);
    }
    .modal-container {
      @extend .custom-scrollbar-falabella;

      .modal-dialog {
        input {
          color: $grey;
          border-color: $grey;
        }
      }
    }
  }
}
