@import "variables";

.error {
  color: $red;
}

form {
  .input-container {
    margin-bottom: 1em;
  }
  .form-group {
    display: flex;
    margin-left: -0.5em;
    margin-right: -0.5em;
    align-items: flex-end;

    .input-container {
      margin-left: 0.5em;
      margin-right: 0.5em;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 33%;
    }
  }
}
