@import "variables";

.modal-vote {
  .button.big {
    width: 100%;
  }

  .credits-message {
    color: $accent;
    font-size: 1.125em;
    line-height: 1.5em;
  }

  .error {
    color: $red;
    border: 1px solid red;
    padding: 1em;
    border-radius: 1em;
  }

  .button.bordered {
    margin-bottom: 1em;
  }
  .modal-container .modal-dialog .points-input-widget input {
    color: $accent;
  }
}

.modal-song-info {
  &,
  &.song {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    text-align: center;

    img.cover {
      width: 72px;
      height: 72px;
      border-radius: 2.5em;
      margin-bottom: 8px;
    }
    .songname {
      font-size: 1.5em;
      margin-bottom: 4px;
    }
    .artist {
      font-size: 1em;
      font-weight: 300;
    }
  }
}

body.falabella {
  .modal-vote {
    .credits-message {
      color: $accent-falabella;
    }

    .error {
      color: $red;
      border: 1px solid red;
    }
    .modal-container .modal-dialog .points-input-widget input {
      color: $brand-falabella;
    }
  }
}
