@import "../../../../styles/variables";

.profile-header {
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2,
  p {
    text-align: center;
  }
  h2 {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
}

.avatar-row {
  display: flex;
  flex-direction: row;
  padding-left: 2em;
  .avatar-container {
    margin: 1em;
  }
}

.avatar-container {
  background-color: $grey;
  color: white;
  width: 6.875em;
  height: 6.875em;
  text-align: center;
  line-height: 6.875em;
  border-radius: 3.485em;
  overflow: hidden;
  border: 2px solid white;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

body,
body.falabella {
  .logout-button.button {
    border: none;
    background: transparent;
    padding: 0;
    .button-content {
      background: transparent;
      padding: 0;
      span {
        display: block;
        text-transform: none;
        font-weight: 400;
        color: $accent;
      }
    }
    img {
      width: 1.5em;
      height: 1.5em;
    }
  }
}
