.task-spritz-rock-music {
  $aperol_primary: #fe5000;
  $aperol_secondary: #eb3300;
  // background: $aperol_primary;
  font-family: "Futura", Gotham, "Gotham", Arial, Helvetica, sans-serif;
  .modal-download-app {
    display: none;
  }
  h1,
  h2 {
    font-weight: 500;
  }
  .layout {
    background: transparent;
    &:before {
      background: transparent;
    }
  }

  button.button:disabled {
    filter: none;
  }

  button.button.brand,
  button.button.success {
    background: white;
    .button-content {
      background: $aperol_primary;
    }
  }
  .task-form-radio-label {
    transition: transform 0.3s;
    &:focus,
    &:hover {
      color: white;
      transform: scale(1.05);

      input {
        border-color: white;
      }
    }
  }
  .task-form-radio-input:checked {
    background: $aperol_primary;
    border-color: white;
  }

  .task-form-radio-input:checked + span {
    color: white;
  }

  button.button.as-transparent.accent {
    color: white;
  }

  .navbar-container {
    display: none;
  }

  .progress-bar__progress {
    background: $aperol_secondary !important;
  }
}
