.task-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    transition: all 0.5s ease-in-out;
    margin: 0;
    max-height: 100px;
    max-width: 100vw;
  }

  > h1 {
    max-height: 0;
  }

  .task-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
      font-size: 1.25rem;
      transform: scale(1);
      opacity: 1;
    }
  }

  .radio-image {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin-right: 1rem;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease-in-out;
  }

  &.task-header-big {
    flex-direction: column;
    align-items: center;

    > h1 {
      max-height: 100px;
    }

    .radio-image {
      width: 200px;
      height: 200px;
      margin: 1rem;
      border-radius: 20px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    }

    .task-header-row {
      h1 {
        transform: scale(0);
        opacity: 0;
        max-width: 0;
      }
    }
  }

  &.task-header-small {
    > h1 {
      transform: scale(0);
      opacity: 0;
    }
  }
}
