@import "fonts";
@import "variables";
@import "custom-scrollbar";

* {
  box-sizing: border-box;
}

body {
  @extend .custom-scrollbar;
  margin: 0;
  &,
  button,
  input,
  textarea {
    font-family: Gotham, "Gotham", Arial, Helvetica, sans-serif;
  }
  background-color: $dark-blue;
  color: white;
}

.layout {
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  // max-width: 768px;
  
  &::before {
    content: "";
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    background-image: url("../images/background.gif");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  > main {
    padding: 75px 0 2em;
    min-height: calc(100vh - 77px);
  }
}

.container {
  width: 100%;
  max-width: 100%;
  @media screen and (min-width: 768px) {
    width: calc(100% - 2em);
    max-width: calc(100% - 2em);
  }
  margin: 0 auto;
}

pre {
  max-width: 100%;
  overflow: hidden;
  overflow-wrap: anywhere;
  word-break: break-all;
  text-overflow: ellipsis;
  background-color: rgba(0, 0, 0, 0.8);
  color: lime;
  padding: 0.5em;
  border-radius: 4px;
}

a {
  color: $brand;
  text-decoration: none;

  &:visited {
    color: $accent;
  }
}

.page-logo {
  margin: 0 auto 4em;
  display: block;
  width: 13.75em;
}

footer.container {
  padding: 1em 2em;
  text-align: center;
  font-size: 0.75em;
}

body.falabella {
  @extend .custom-scrollbar-falabella;
  // background-color: $dark-blue;
  background: #2d3b21;
  color: white;

  .layout {
    &::before {
      background-image: url("../images/background-falabella.gif");
    }
  }

  pre {
    background-color: rgba(0, 0, 0, 0.8);
    color: lime;
  }

  a {
    color: $brand-falabella;

    &:visited {
      color: $accent-falabella;
    }
  }
}
