.sonicboxes {
  padding: 2em;
}

.main-msg {
  text-align: center;
  font-size: 2em;
  font-weight: 300;
}

p {
  text-align: center;
}

.map-loading {
  width: 468px;
  max-width: calc(100% - 4em);
  display: block;
  margin: 0 auto 4em;
}

.locals-container {
  margin: 0;
  padding: 0;
}

.search-wrapper {
  display: flex;
  justify-content: space-between;

  .search-container {
    flex-grow: 1;
  }

  button.search-btn {
    margin-left: auto;
    &,
    &.button.as-link {
      padding: 5px 0.625em;
      font-size: 1.25em;
      &:hover {
        text-decoration: none;
      }
    }

    .icon {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      margin-left: 6px;
      margin-right: 6px;
      transform: scaleX(-1);
      height: 18px;
      margin-bottom: -2px;
      width: auto;
    }
  }
}
